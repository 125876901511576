import 'virtual:accessible';
import 'virtual:spritemap';
import 'virtual:theme.css';
import '../css/main.scss';

addEventListener('DOMContentLoaded', () => {

});

import icons from 'virtual:icons';
import theme from 'virtual:theme';

if (import.meta.env.DEV) {
  console.info('%cws-vite ' + import.meta.env.WS_VITE, 'color: #757575');
  // console.log('env', import.meta.env);
  console.log('icons', icons);
  console.log('theme', theme);
}

